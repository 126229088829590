<style lang="less" scoped>
  .white-block{
    background: white;
    border-radius: 8px;
    padding: 24px;
  }
  .content{
    height: 100%; padding-right: 16px;
    // 退货订单统计
    .total{
      &-title{
        font-size: 18px; font-weight: 600; line-height: 26px;
        & > span{ color: #666; font-weight: 400; font-size: 16px; margin-left: 8px; }
      }
      &-flex{
        display: flex; background: #F8F9FA; border-radius: 8px; margin-top: 16px;
        &-item{
          width: 25%; text-align: center; padding: 16px 10px; box-sizing: border-box;
          h4{ margin: 0; font-weight: 500; font-size: 36px; line-height: 54px; color: #222; }
          p{ line-height: 25px; font-size: 16px; color: #666; }
        }
      }
    }
    // 筛选
    .select-tool{
      margin-top: 16px;
      .select-btns{
        padding-top: 8px;
        .select-button{
          background: white; color: #222; font-size: 14px; border:solid 1px #ddd; width: 74px; height: 32px; padding: 0; line-height: 30px;
          &:first-child{ border-color: #FF791F; color: #FF791F; }
          &:hover{ background: #FF791F; color: white; border-color: #FF791F; }
        }
      }
      .select-flex{
        margin: 0 -8px; display: flex; flex-wrap: wrap;
        &-item{
          width: calc(100% / 3); padding: 8px; box-sizing: border-box;
          .select-input{
            width: 100%; height: 32px; border-radius: 4px;
            /deep/ .el-input__inner::placeholder{ color: #ccc; }
            label{ line-height: 32px; color: #999; padding: 0 10px; width: 120px; display: block; text-align: left; }
          }
          .select-date{
            border: 1px solid #DCDFE6; border-radius: 4px; position: relative; display: flex; align-items: center; padding-right: 45px; box-sizing: border-box;
            & > label{ line-height: 32px; color: #999; padding: 0 10px; font-size: 13px; width: 90px;  }
            & > i　{ position: absolute; right: 15px; color: #898B8F; }
            /deep/ .el-range-input::placeholder{ color: #ccc; }
          }
        }
      }
    }
    // 订单列表
    .lists{
      margin-top: 16px;
      &-title{
        font-size: 18px; line-height: 26px; font-weight: 600; display: flex; align-items: center; justify-content: space-between;
        button{ background: #FF791F; color: white; border: none; height: 32px; padding: 0; line-height: 32px; width: 74px; }
      }
      .lists-box{
        margin: 16px 0;
        .ys-status{
          text-align: center; font-size: 12px;
          span{ background: #f6f7f8; width: 6px; height: 6px; display: inline-block; border-radius: 50%; margin-top: -2px; }
        }
        .cancel-reason{
          font-size: 12px;
          &.show{ color: #FF0000; }
        }
      }
    }
  }
</style>
<style lang="less">
  .select-date{
    .el-date-editor{ flex: 1; border: none; }
    .el-input__inner{ padding-left: 10px !important; }
  }
</style>
<template>
  <div class="content">
    <!-- 统计信息 -->
    <div class="total white-block">
      <div class="total-title">退货订单明细<span>此版块展示当月累计数据</span></div>
      <div class="total-flex">
        <div class="total-flex-item">
          <h4>{{ headerTotal.total }}</h4>
          <p>累计退货单数（单）</p>
        </div>
        <div class="total-flex-item">
          <h4>{{ headerTotal.express }}</h4>
          <p>产生物流单号总数（个）</p>
        </div>
        <div class="total-flex-item">
          <h4>{{ headerTotal.is_yt }}</h4>
          <p>【昕庭速寄】总单数（单）</p>
        </div>
        <div class="total-flex-item">
          <h4>{{ headerTotal.rebate }}</h4>
          <p>产生收益总单数（单）</p>
        </div>
      </div>
    </div>
    <!-- 筛选条件 -->
    <div class="select-tool white-block">
      <div class="select-flex">
        <div class="select-flex-item">
          <el-input
              v-model="orderNo"
              class="select-input"
              type="text"
              size="small"
              placeholder="请输入退货订单号">
            <template #prefix>
              <label>退货订单号</label>
            </template>
          </el-input>
        </div>
        <div class="select-flex-item">
          <el-input
              v-model="goodsNo"
              class="select-input"
              type="text"
              size="small"
              placeholder="请输入商品名称">
            <template #prefix>
              <label>商品名称</label>
            </template>
          </el-input>
        </div>
        <div class="select-flex-item">
          <el-select v-model="logistics.value" class="select-input" size="small" placeholder="请选择">
            <el-option
                v-for="item in logistics.lists"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
            <template #prefix>
              <label>物流状态</label>
            </template>
          </el-select>
        </div>
        <div class="select-flex-item">
          <div class="select-date">
            <label>退货时间</label>
            <i class="el-icon-date" @click="onSelectData('returnRef')"></i>
            <el-date-picker
                v-model="retTime"
                size="small"
                ref="returnRef"
                type="daterange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                prefix-icon=" "
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
<!--        <div class="select-flex-item">-->
<!--          <el-input-->
<!--              v-model="devNo"-->
<!--              class="select-input"-->
<!--              type="text"-->
<!--              size="small"-->
<!--              placeholder="请输入物流单号">-->
<!--            <template #prefix>-->
<!--              <label>物流单号</label>-->
<!--            </template>-->
<!--          </el-input>-->
<!--        </div>-->
        <div class="select-flex-item">
          <div class="select-date">
            <label>订单完成时间</label>
            <i class="el-icon-date" @click="onSelectData('completeRef')"></i>
            <el-date-picker
                v-model="confirmTime"
                ref="completeRef"
                size="small"
                type="daterange"
                prefix-icon=" "
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <div class="select-flex-item">
          <el-select v-model="serves.value" class="select-input" size="small" placeholder="请选择">
            <el-option
                v-for="item in serves.lists"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
            <template #prefix>
              <label>是否使用寄件服务</label>
            </template>
          </el-select>
        </div>
      </div>
      <div class="select-btns">
        <el-button class="select-button" size="medium" plain @click="onSubmit">查询</el-button>
        <el-button class="select-button" size="medium" plain @click="onInfoList">重置</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <div class="white-block lists">
      <div class="lists-title">
        退货订单列表
        <el-button @click="onLTableImport">导出</el-button>
      </div>
      <div class="lists-box">
        <el-table :data="orderList" style="width: 100%">
          <el-table-column label="序号" align="center" width="50">
            <template v-slot="scope">
              <div :class="['cancel-reason']">{{ scope.$index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column label="退货订单号" width="100">
            <template v-slot="scope">
              <div :class="['cancel-reason']">{{ scope.row.order_refund_id }}</div>
            </template>
          </el-table-column>
          <el-table-column label="退货时间" width="150">
            <template v-slot="scope">
              <div :class="['cancel-reason']">{{ scope.row.refund_apply_time }}</div>
            </template>
          </el-table-column>
          <el-table-column label="商品名称">
            <template v-slot="scope">
              <div :class="['cancel-reason']">{{ scope.row.product_name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="订单金额（元）" width="120" align="right">
            <template v-slot="scope">
              <div :class="['cancel-reason']">{{ scope.row.price }}</div>
            </template>
          </el-table-column>
          <el-table-column label="物流单号" width="150">
            <template v-slot="scope">
              <div :class="['cancel-reason']">{{ scope.row.logistics_code }}</div>
            </template>
          </el-table-column>
          <el-table-column label="是否使用【昕庭速寄】" width="148">
            <template v-slot="scope">
              <div :class="['cancel-reason']">{{ scope.row.is_yt ? '是': '否' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="物流状态" width="120" align="center">
            <template v-slot="scope">
              <div class="ys-status" v-if="scope.row.yt_status">
                <span :style="{ background: getDevState(scope.row.yt_status).color }"></span>
                {{ getDevState(scope.row.yt_status).label }}
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="订单下单时间" width="150">
            <template v-slot="scope">
              <div :class="['cancel-reason']">{{scope.row.order_time || '-'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="订单完成时间" width="150">
            <template v-slot="scope">
              <div :class="['cancel-reason']">{{scope.row.sign_time || '-'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="备注" width="140">
            <template v-slot="scope">
              <div v-if="scope.row.yt_status === 9 || scope.row.yt_status === 12 || scope.row.yt_status === 7 || scope.row.yt_status === 8">
                <div class="cancel-reason show" v-if="scope.row.yt_status === 9">用户已取消</div>
                <div class="cancel-reason show" v-else-if="scope.row.yt_status === 12">用户取消拦截失败</div>
                <div class="cancel-reason show" v-if="scope.row.yt_status === 7">用户签收失败</div>
                <div class="cancel-reason show" v-if="scope.row.yt_status === 8">快递已退回</div>
              </div>
              <div class="cancel-reason" v-else>-</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="lists-page">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :total="pages.total"
            :page-size="pages.per_page"
            @current-change="pagesChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { returnOrderDetails, returnOrderList, returnOrderListImport } from "@/api/returnOrder";
import moment from 'moment';
export default {
  name: "details",
  data() {
    return {
      headerTotal : {
        total   : 0,
        express : 0,
        is_yt   : 0,
        rebate  : 0
      },
      orderNo     : '',
      goodsNo     : '',
      devNo       : '',
      retTime     : '',
      confirmTime : '',
      logistics   : {
        value: 0,
        lists: [
          { id: 0, label: '全部' },
          { id: 1, label: '已签收' },
          { id: 2, label: '运输中' },
          { id: 3, label: '已结束' }
        ]
      },
      serves    : {
        value: '',
        lists: [
          { id: '', label: '全部' },
          { id: 2, label: '是' },
          { id: 1, label: '否' },
        ]
      },
      orderList : [],
      pages     : {
        total       : 0,
        per_page    : 0,
        current_page: 1,
        last_page   : 0
      }
    };
  },
  computed: {
    getDevState(){
      return (e) => {

        // 1：待支付 2：已支付待揽收 3：已揽收 4：进行中 5：待签收 6：签收成功 7：签收失败 8：退回 9：已取消 10：已拦截 11：取消拦截中 12：取消拦截失败

        if(e === 6){
          return { label: '已签收', color: '#3DCC85' };
        }else if(e === 9 || e === 12 || e === 7 || e === 8 ){
          return { label: '已结束', color: '#FF3B52' };
        }else{
          return { label: '运输中', color: '#0077FF' };
        }
      }
    }
  },
  methods: {
    onSelectData(e){
      this.$refs[e].focus()
    },
    // 切换分页
    pagesChange(e){
      if(this.pages.current_page != e){
        let formData = this.getFormData()
        this.getList({
          ...formData,
          offset: e,
          size  : this.pages.per_page
        })
      }
    },
    // 整合组织一个表单数据
    getFormData(){
      let { orderNo,  goodsNo,  devNo,  retTime,  confirmTime,  logistics,  serves } = this;
      return {
        refund_id         : orderNo,
        good_info         : goodsNo,
        logistics_status  : logistics.value || '',
        refund_start_time : retTime[0] || '',
        refund_end_time   : retTime[1] || '',
        logistics_number  : devNo,
        is_yt             : serves.value ? serves.value - 1 : '',
        order_start_time  : confirmTime[0] || '',
        order_end_time    : confirmTime[1] || ''
      }
    },
    // 提交查询表单
    onSubmit(){
      let formData = this.getFormData()
      this.getList({
        ...formData,
        offset: 1,
        size  : this.pages.per_page
      })
    },
    // 初始化列表
    onInfoList(){
      this.orderNo          = ''
      this.goodsNo          = ''
      this.devNo            = ''
      this.retTime          = ''
      this.confirmTime      = ''
      this.logistics.value  = 0
      this.serves.value     = ''
      this.getList()
    },
    // 导出表格
    onLTableImport(){
      let formData = this.getFormData()
      if(formData.refund_start_time != '' && formData.refund_end_time != ''){
        let startDate = moment(formData.refund_start_time)
        let endDate   = moment(formData.refund_end_time)
        let diffDay   = endDate.diff(startDate, 'days')
        if(diffDay > 30){
          this.$message.error('只允许导出30天的数据')
          return
        }
      }
      returnOrderListImport({
        ...formData,
        blob: true
      }).then(res => {
        const aline = document.createElement("a");
        const blob = new Blob([res])
        aline.download = '退货订单.xlsx'
        aline.style.display = 'none'
        aline.href = URL.createObjectURL(blob)
        aline.click()
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    // 获取列表
    getList(params){
      returnOrderList(params || {}).then(res => {
        let { data, code } = res;
        if( code === 0 ){
          this.orderList = data.data;
          this.pages     = {
            total       : data.total,
            per_page    : data.per_page,
            current_page: data.current_page,
            last_page   : data.last_page
          }
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    }
  },
  mounted() {
    // 数据明细
    returnOrderDetails().then(res => {
      let { code, data } = res;
      if(code === 0) this.headerTotal = data
    }).catch(err => {
      this.$message.error(err.message);
    })
    // 获取列表
    this.getList()
  }
}
</script>